import axios from 'axios'

const FeedbackService = {
  checkInMinutes: 10,
  checkInTimer: null,

  async search (data) {
    return axios({
      url: axios.defaults.baseURL + 'feedback/search',
      method: 'POST',
      data: JSON.stringify(data)
    })
  }
}

export default FeedbackService
