<template>
  <b-form
    v-if="!loading"
    @submit.prevent="send"
  >
    <b-form-group
      label-for="message"
      label-cols="4"
      label-cols-sm="3"
      label-cols-md="2"
      label-align="right"
    >
      <b-form-textarea
        v-if="role === 'bookkeeper' || role === 'admin'"
        v-model="message.content"
        ref="message"
      ></b-form-textarea>
      <b-form-textarea
        v-else
        v-model="message.content"
        ref="message"
        required
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      label="Documents:"
      label-for="documents"
      label-cols="4"
      label-cols-sm="3"
      label-cols-md="2"
      label-align="right"
    >
      <b-form-file
        v-model="message.files"
        multiple
      > </b-form-file>
    </b-form-group>

    <b-row align-h="between">
      <b-col
        class="text-left"
        offset="4"
        offset-sm="3"
        offset-md="2"
      >
        <slot></slot>
      </b-col>
      <b-col class="text-right">
        <b-button
          type="button"
          @click="close"
          variant="secondary"
          class="ml-1"
          size="sm"
          :disabled="loading"
        >
          <b-icon icon="x" />
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="success"
          class="ml-1"
          size="sm"
          :disabled="loading"
        >
          <b-icon icon="check" />
          Send
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import MessageService from '@/services/MessageService'

export default {
  name: 'MessageSend',

  props: {
    transactionId: {
      type: [String, Number],
      default: 0
    },
    role: {
      type: String,
      default: 'user'
    }
  },

  data () {
    return {
      loading: false,
      message: {
        content: null,
        files: []
      }
    }
  },

  mounted () {
    this.$nextTick(() => this.$refs.message.focus())
  },

  methods: {
    send () {
      this.loading = true
      const formData = new FormData()
      if (!this.message.content) {
        this.message.content = '<p><strong>Please provide more detail regarding this transaction.</strong></p><ul><li><strong>Itemized breakdown</strong></li><li><strong>Purpose</strong></li><li><strong>Justification</strong></li></ul>'
      }
      formData.append('content', this.message.content)
      formData.append('qbtransaction_id', this.transactionId)
      if (this.message.files && this.message.files.length) {
        this.message.files.forEach((f) => {
          formData.append('files[]', f, f.name)
        })
      }

      MessageService.send(formData).then((response) => {
        this.$aimNotify.notify(response)
        this.close()
        this.$emit('refresh')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
        console.dir(err)
      }).finally(() => {
        this.loading = false
      })
    },

    close () {
      this.message.content = null
      this.message.files = []
      this.$emit('close')
    }
  }
}
</script>
