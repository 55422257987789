<template>
  <div v-if="transaction">
    <b-row
      v-if="transaction.messages && transaction.messages.length"
      class="m-0 px-3 pb-0 pt-1"
      no-gutters
    >
      <b-col>
        <MessageList
          :messages="transaction.messages"
          :total="transaction.messages.length"
          @update="updateMessage"
        ></MessageList>
      </b-col>
    </b-row>

    <b-row
      class="m-0 px-3 pt-3 pb-1"
      no-gutters
    >
      <b-col
        cols="12"
        md="4"
        class="text-left"
        style="color: #BE191E; padding-left: 265px; font-size: large;"
      >
        <p><strong>Please provide more detail regarding this transaction.</strong></p>
        <ul>
          <li><strong>Itemized breakdown</strong></li>
          <li><strong>Purpose</strong></li>
          <li><strong>Justification</strong></li>
        </ul>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <MessageSend
          :transaction-id="+transaction.id"
          :role="role"
          @close="toggleDetailRow(transaction)"
          @refresh="$emit('refresh')"
        >
          <b-button
            variant="primary"
            @click.prevent="viewTransaction(transaction)"
            size="sm"
          >View Transaction Detail</b-button>
        </MessageSend>
      </b-col>
    </b-row>

    <b-row
      class="m-0 pt-2"
      no-gutters
      @click.prevent="toggleDetailRow(transaction)"
    >
      <b-col class="border-top px-0">
        <b-icon-chevron-double-up
          size="2"
          class="pointer"
        ></b-icon-chevron-double-up>
      </b-col>
    </b-row>

    <b-modal
      v-model="showTransactionDetail"
      size="lg"
      v-if="selectedTransaction"
      :title="'Transaction ID ' + selectedTransaction.id"
      no-close-on-backdrop
      no-close-on-esc
      @close="selectedTransaction = null"
      @cancel="selectedTransaction = null"
    >
      <TransactionDetail :transaction="selectedTransaction"></TransactionDetail>
    </b-modal>
  </div>
</template>
<script>
import TransactionDetail from '@/components/bookkeeping/widgets/TransactionDetail'
import MessageSend from '@/components/feedback/MessageSend'
import MessageList from '@/components/feedback/MessageList'

export default {
  name: 'Messaging',
  components: { TransactionDetail, MessageSend, MessageList },

  props: {
    transaction: Object,
    role: {
      type: String,
      default: 'user'
    }
  },

  data () {
    return {
      updated: false,
      showTransactionDetail: false,
      selectedTransaction: null
    }
  },

  methods: {
    updateMessage (msg) {
      this.updated = true
      this.$emit('update', msg)
    },

    toggleDetailRow (item) {
      this.$emit('toggle', item)
      if (this.updated) {
        this.updated = false
        this.$emit('refresh')
      }
    },

    viewTransaction (t) {
      this.selectedTransaction = t
      if (Object.prototype.toString.call(this.selectedTransaction.data) === '[object String]') {
        this.selectedTransaction.data = JSON.parse(t.data)
      }
      if (this.selectedTransaction) {
        this.selectedTransaction.account = {
          name: t.plaidaccount,
          type: t.plaidaccounttype
        }
      }
      this.showTransactionDetail = true
    }
  }
}
</script>
