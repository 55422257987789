<template>
  <b-table-simple class="text-left">
    <b-tbody>
      <b-tr>
        <b-th class="text-right">Account:</b-th>
        <b-td>{{ (transaction.plaidaccount) ? transaction.plaidaccount : transaction.linkedqbaccount }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Account Type:</b-th>
        <b-td>{{ transaction.plaidaccounttype }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Business Date:</b-th>
        <b-td>{{ transaction.businessdate }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Amount:</b-th>
        <b-td>{{ transaction.amount | currency }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Transaction Name:</b-th>
        <b-td>{{ transaction.name }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Category:</b-th>
        <b-td>{{ transaction.category }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Merchant Name:</b-th>
        <b-td>{{ transaction.data.merchant_name }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Payment Channel:</b-th>
        <b-td>{{ transaction.data.payment_channel }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Transaction Type:</b-th>
        <b-td>{{ transaction.data.transaction_type }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">Check Number:</b-th>
        <b-td>{{ transaction.data.check_number }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
export default {
  name: 'TransactionDetail',
  props: {
    transaction: Object
  }
}
</script>
