<template>
  <b-container
    fluid
    class="p-0"
  >
    <b-row class="pb-3">
      <b-col
        cols="12"
        md="5"
      >
        <b-table-simple
          small
          class="text-left"
        >
          <b-tr>
            <b-td class="text-right">Company:</b-td>
            <b-td>{{ message.company }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">From:</b-td>
            <b-td>{{ message.sender }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">To:</b-td>
            <b-td>{{ message.recipient }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Email:</b-td>
            <b-td>{{ message.email }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Sent:</b-td>
            <b-td>{{ message.created }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Read:</b-td>
            <b-td>{{ message.viewed }}</b-td>
          </b-tr>
        </b-table-simple>

        <div v-if="message.documents && message.documents.length">
          <hr />
          <h4 class="text-center m-0">Attachments</h4>
          <ul class="text-left ml-5">
            <li
              v-for="d in message.documents"
              :key="d.id"
            >
              <a
                style="color: black;"
                :href="d.url"
              >{{ d.documentname }}</a>
            </li>
          </ul>
        </div>
      </b-col>

      <b-col
        cols="12"
        md="7"
        class="text-left"
      >
        <b-card no-body>
          <b-card-header>
            <h4 class="m-0">Subject: {{ message.subject }}</h4>
          </b-card-header>
          <b-card-text class="m-3">
            <span v-html="message.content"></span>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="modal-footer">
      <b-col class="text-left m-0 p-0">
        <b-button
          v-if="viewed"
          variant="danger"
          @click.prevent="markAsUnread"
        >Mark As Unread</b-button>
      </b-col>
      <b-col class="text-right m-0 p-0">
        <b-button
          variant="primary"
          @click.prevent="$emit('close')"
        >Close</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { bus } from '@/main'
import MessageService from '@/services/MessageService'
import UserService from '@/services/UserService'

export default {
  name: 'MessageView',
  props: {
    message: {
      type: Object,
      default: () => { return null }
    }
  },

  data () {
    return {
      userId: 0,
      viewed: false
    }
  },

  async mounted () {
    const userinfo = await UserService.getUserInfo()
    if (this.message) {
      MessageService.get(+this.message.id).then((response) => {
        if (response && response.data.result && response.data.info.viewed) {
          this.viewed = true
          this.$emit('update', response.data.info)
          bus.$emit('messageRead', new Date())
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      })
    } else if (this.message && +this.message.viewedby_id === +userinfo.id) {
      this.viewed = true
    }
  },

  methods: {
    markAsUnread () {
      MessageService.markAsUnread(+this.message.id).then((response) => {
        this.$emit('update', response.data.info)
        this.$emit('close')
        bus.$emit('messageRead', new Date())
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      })
    }
  }
}
</script>
