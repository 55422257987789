<template>
  <b-card
    no-body
    bg-variant="light"
  >
    <b-card-header class="p-1">
      <b-card-title class="m-0">
        <h6 class="m-0 pl-2 text-left"><strong>Message History</strong></h6>
      </b-card-title>
    </b-card-header>

    <b-table
      id="messageListTable"
      striped
      hover
      small
      bordered
      sticky-header="300px"
      :fields="fields"
      :items="messages"
      primary-key="id"
      :tbody-tr-class="rowClass"
      details-td-class="w-100 p-0"
      class="mb-0"
      @row-clicked="view"
    >
      <template #table-colgroup>
        <col width="15%">
        <col width="20%">
        <col>
        <col width="0*">
      </template>

      <template #cell(id)="data">
        <b-icon-info-circle-fill
          size="1"
          variant="primary"
          class="pointer"
          @click.prevent="view(data.item)"
        ></b-icon-info-circle-fill>
      </template>
    </b-table>

    <b-modal
      id="message-view-modal"
      v-model="showMessageDetail"
      size="lg"
      v-if="selectedMessage"
      :title="'Message ID ' + selectedMessage.id"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      button-size="sm"
      @close="selectedMessage = null"
    >
      <MessageView
        :message="selectedMessage"
        @update="updateMessage"
        @close="$root.$emit('bv::hide::modal', 'message-view-modal')"
      ></MessageView>
    </b-modal>
  </b-card>
</template>
<script>
import MessageView from '@/components/feedback/MessageView'

export default {
  name: 'MessageList',
  components: { MessageView },
  data () {
    return {
      showMessageDetail: false,
      selectedMessage: null,
      fields: [
        { key: 'sender', sortable: true },
        { key: 'created', sortable: true },
        { key: 'summary', sortable: true, thClass: 'text-left', tdClass: 'text-left' },
        { key: 'id', label: '' }
      ]
    }
  },

  props: {
    messages: {
      type: Array,
      default: () => { return [] }
    },

    total: {
      type: [Number, String],
      default: 0
    }
  },

  methods: {
    view (data) {
      this.selectedMessage = data
      this.showMessageDetail = true
    },

    updateMessage (msg) {
      this.selectedMessage = msg
      this.$emit('update', this.selectedMessage)
      this.$nextTick(() => {
        this.$root.$emit('bv::refresh::table', 'messageListTable')
      })
    },

    rowClass (item, type) {
      let classes = ''
      if (!item || type !== 'row') return
      if (!item.viewed) classes += ' font-weight-bold'
      if (this.selectedMessage && +item.id === +this.selectedMessage.id) classes += ' bg-secondary'
      return classes
    }
  },

  computed: {
    /**
     * @returns {Number}
     */
    start: function () {
      return ((+this.page - 1) * +this.perPage) + 1
    },
    /**
     * @returns {Number}
     */
    end: function () {
      return ((+this.page * +this.perPage) > +this.total) ? +this.total : (+this.page * +this.perPage)
    }
  }
}
</script>
